import React, { useState, useEffect } from "react";
import Media from "react-media";
//import hero_video from "./media/videos/hero_video.mp4";
import hero_video from "./media/videos/hero_3_computer.mp4";

import "./App.css";
import "./Technologies.css";

function Technologies() {
  const [blockOnHover, setBlockOnHover] = useState(null);
  useEffect(() => {
    window.addEventListener("scroll", (e) => console.log("ai ai ai " + e));
    // Update the document title using the browser API
    //document.title = `You clicked ${window.scrollY} times`;
  });
  return (
    <div className="Technologies">
      <Media
        queries={{
          small: "(max-width: 599px)",
          medium: "(min-width: 600px) and (max-width: 1199px)",
          large: "(min-width: 1200px)",
        }}
      >
        {(screenSize) => <div>{renderTechnologies(screenSize)}</div>}
      </Media>
    </div>
  );

  function renderTechnologies(screenSize) {
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          width: "100vw",
          backgroundColor: "#000c",
          overflow: "hidden",
        }}
      >
        <video
          src={hero_video}
          autoPlay
          loop
          muted
          style={{
            display: "fixed",
            objectFit: "cover",
            height: "100vh",
            width: "100vw",
            overflow: "hidden",
            zIndex: "-1000",
          }}
        ></video>
        <div
          style={{
            top: "0px",
            left: "0px",
            height: "100vh",
            width: "100vw",
            position: "absolute",
            zIndex: "10",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "start",
              alignItems: "start",
              flexDirection: screenSize.small ? "column" : "row",
              //backgroundColor: "red",
              //fontSize: "calc(5vw)",
            }}
          >
            {RenderMainLeftBlock(screenSize)}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                overflowY: "scroll",
                width: screenSize.small ? "100%" : "60vw",
                height: "100vh",
                //backgroundColor: "red",
                scrollSnapType: "y mandatory",
              }}
            >
              <div style={{ height: "auto" }}>
                {/* {renderTechnologyBlock(
                  "Automate and speed up your business using the last government APIs, and ROBOTs that can finish tasks at the speed of... computers ",
                  "Finance & Accounting",
                  "https://source.unsplash.com/E7RLgUjjazc"
                )} */}
                {renderTechnologyBlock(
                  "One Stop Shop or 'Fullstack' in geek language - We develop end-to-end solutions and have entire companies running on top of PO software, developed to meet specific customer requirements.",
                  "Fullstack Development",
                  "https://source.unsplash.com/DuHKoV44prg"
                )}
                {renderTechnologyBlock(
                  "Its time to develop & automate businesses, while saving money. Automate government platforms, send thousands of emails, automate your monthly tax submission. There is no limit for what can be accelerated. Always saving money on the run.",
                  "Robotization & Automation",
                  "https://source.unsplash.com/GpNOhig3LSU"
                )}
                {renderTechnologyBlock(
                  "All the great things are turning mobile. Your business can easily grow new branches just by going mobile.",
                  "Mobile",
                  "https://source.unsplash.com/A6qNzfJXRGQ"
                )}
                {renderTechnologyBlock(
                  "Nature and IT should work together. Part of our crew has strong electronics background. In the past we took part in projects related with automated agriculture and sensor logging.",
                  "IoT, Sensors and device automation",
                  "https://source.unsplash.com/sD-gJzsrQt4"
                )}
                {renderTechnologyBlock(
                  "Tailor made geographic tools, geo data analysis, vehicle data, or any other tailor made geo tool that is missing on your company. ",
                  "Maps and Geo tools",
                  "https://source.unsplash.com/no2blvVYoJw"
                )}
                {renderTechnologyBlock(
                  "Government platforms like CITIUS, SEF & AT (Portugal) can be tough, but fortunately we have the ability of automating repetitive tasks, like checking hundreds of processes, or deliver monthly mandatory documents, with robots and artificial intelligence.",
                  "Law & Government",
                  "https://source.unsplash.com/DZpc4UY8ZtY"
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderTechnologyBlock(text, title, picture) {
    return (
      <div
        className="technologyBlock moveImageOnHover"
        onMouseEnter={() => setBlockOnHover(title)}
        onMouseLeave={() => setBlockOnHover(null)}
        style={{
          width: "100%",
          height: "calc(60vh)",
          scrollSnapAlign: "center",
          // backgroundColor: hovered ? "red" : "white",
          backgroundImage: "url(" + picture + ")",
          //backgroundPosition: "center",
          backgroundPosition: blockOnHover === title ? "30% 70%" : "50% 50%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          filter: blockOnHover === title ? "grayscale(20%)" : "grayscale(100%)",
        }}
      >
        <div
          className="technologyBlock"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "start",
            width: "100%",
            height: "100%",
            backgroundColor: blockOnHover === title ? "#0004" : "#0009",
            boxSizing: "border-box",
            padding: 40,
          }}
        >
          <div
            // className={"moveOnHover"}
            className="doThingsSlowly"
            style={{
              color: "#fff",
              fontSize: 40,
              fontFamily: "Ubuntu",
              padding: blockOnHover === title ? 3 : 0,
            }}
          >
            {title}
          </div>
          <div
            // className={blockOnHover === title ? "moveOnHover" : null}
            style={{
              marginTop: "30px",
              color: "#fff",
              fontSize: 14,
              fontWeight: 600,
              fontFamily: "Ubuntu",
              maxWidth: "400px",
              lineHeight: 1.6,
            }}
          >
            {text}
          </div>
        </div>
      </div>
    );
  }

  function RenderMainLeftBlock(screenSize) {
    return (
      <div
        style={{
          width: screenSize.small ? "100%" : "40vw",
          height: "100vh",
          backgroundColor: "#3338",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ fontSize: 50, fontFamily: "Ubuntu", color: "#fff" }}>
            need for<span style={{ color: "#FF7F50" }}> Skill.</span>
          </div>
        </div>

        <div
          style={{
            fontSize: 20,
            fontFamily: "Ubuntu",
            color: "#fff",
            width: "50%",
            marginTop: "100px",
          }}
        >
          Our job is to bring complex ideas to a simple reality
        </div>
      </div>
    );
  }
}
// const handleNavigation = (e) => {
//   const window = e.currentTarget;

//   if (this.prev > window.scrollY) {
//     console.log("scrolling up");
//   } else if (this.prev < window.scrollY) {
//     console.log("scrolling down");
//   }
//   this.prev = window.scrollY;
// };
export default Technologies;
